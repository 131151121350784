/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';

import Image from 'SourceComponent/Image';
import Link from 'SourceComponent/Link';

import { BrandSliderItemType, BrandSliderType } from '../../type/BrandsType';

import './BrandSliderItem.style';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandSliderItem/Component/BrandSliderItemComponent */
export class BrandSliderItemComponent extends PureComponent {
    static propTypes = {
        item: BrandSliderItemType.isRequired,
        params: BrandSliderType.isRequired
    };

    renderBrandSliderImage() {
        const {
            item: {
                img,
                alt
            } = {},
            params: {
                imageWidth,
                imageHeight
            } = {}
        } = this.props;
        const imageHeightOptional = imageHeight || imageWidth;

        if (!img) {
            return this.renderBrandFirstLetter();
        }

        return (
            <div
              block="BrandSliderItem"
              elem="Image"
              style={ { width: imageWidth, height: imageHeightOptional } }
            >
                <Image
                  ratio="custom"
                  src={ img }
                  alt={ alt }
                  title={ alt }
                />
            </div>
        );
    }

    renderBrandFirstLetter() {
        const {
            item: {
                label
            } = {},
            params: {
                imageWidth,
                imageHeight
            } = {}
        } = this.props;

        const imageHeightOptional = imageHeight || imageWidth;
        const labelFirstChar = label[0];

        return (
            <div
              block="BrandSliderItem"
              elem="NoImage"
              style={ {
                  width: imageWidth,
                  height: imageHeightOptional,
                  fontSize: imageHeightOptional
              } }
            >
            { labelFirstChar }
            </div>
        );
    }

    renderBrandSliderLabel() {
        const {
            item: { label } = {},
            params: { showLabel }
        } = this.props;

        if (!label || !showLabel) {
            return null;
        }

        return (
            <div mix={ { block: 'BrandSliderItem', elem: 'Label' } }>
                { label }
            </div>
        );
    }

    renderLink() {
        const {
            item: {
                brandId,
                url
            } = {}
        } = this.props;

        return (
            <Link
              to={ {
                  pathname: url,
                  state: {
                      brandId
                  }
              } }
              block="BrandSliderItem"
              elem="Link"
            >
                { this.renderBrandSliderImage() }
                { this.renderBrandSliderLabel() }
            </Link>
        );
    }

    render() {
        return (
            <div block="BrandSliderItem">
                { this.renderLink() }
            </div>
        );
    }
}

export default BrandSliderItemComponent;
