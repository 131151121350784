/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import BrandSlider from '../../component/BrandSlider';
import { BRAND_SLIDER_CMS_TYPE } from '../../component/BrandSlider/BrandSlider.config';
import BrandsList from '../../component/BrandsList';
import { BRAND_LIST_CMS_TYPE, WIDGET_LAYOUT } from '../../component/BrandsList/BrandList.config';

const addBrandSliderToWidgets = (args, callback, instance) => {
    const {
        type
    } = instance.props;

    const widget = {
        params: instance.props
    };

    if (type === BRAND_SLIDER_CMS_TYPE) {
        return (
            <BrandSlider widget={ widget } />
        );
    }

    if (type === BRAND_LIST_CMS_TYPE) {
        const widget = {
            params: {
                ...instance.props
            }
        };

        return (
            <BrandsList
              widget={ widget }
              layout={ WIDGET_LAYOUT }
            />
        );
    }

    return callback(...args);
};

export default {
    'Component/WidgetFactory/Component': {
        'member-function': {
            renderContent: addBrandSliderToWidgets
        }
    }
};
