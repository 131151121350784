/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { BrandSliderItemType, BrandSliderType } from '../../type/BrandsType';
import BrandSliderItem from './BrandSliderItem.component';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandSliderItem/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandSliderItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandSliderItem/Container/BrandSliderItemContainer */
export class BrandSliderItemContainer extends PureComponent {
    static propTypes = {
        item: BrandSliderItemType.isRequired,
        params: BrandSliderType.isRequired
    };

    containerFunctions = {};

    containerProps() {
        const { item, params } = this.props;

        return { item, params };
    }

    render() {
        return (
            <BrandSliderItem
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandSliderItemContainer);
