import {
    SliderComponent as SourceSliderComponent
} from 'SourceComponent/Slider/Slider.component';
import {ReactElement} from "Type/Common.type";

export class SliderComponent extends SourceSliderComponent {
    renderCounter(): ReactElement {
        //@ts-ignore
        const { numberOfSlider, showCounter, activeImage } = this.props;

        if (!showCounter || numberOfSlider <= 1) {
            return null;
        }

        return (
            <div
                block="Slider"
                elem="Counter"
            >
                { activeImage + 1 }
                /
                { numberOfSlider }
            </div>
        );
    }
}

export default SliderComponent;
