/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const BRAND_LIST_LU_TYPE = 'Amasty\\ShopbyBrand\\Block\\Widget\\BrandList';
export const BRAND_LIST_CMS_TYPE = 'AmastyShopByBrandList';
export const BRAND_LIST_DEFAULT_FILTER = 'all brands';
export const DEFAULT_IMAGE_WIDTH = 100;
export const DEFAULT_COLUMN_COUNT = 4;
export const WIDGET_LAYOUT = 'widget';
export const OVERLAY_LAYOUT = 'overlay';
