/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import Image from 'SourceComponent/Image';
import Link from 'SourceComponent/Link';

import { BrandsType } from '../../type/BrandsType';

import './BrandsListItem.style';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsListItem/Component/BrandsListItemComponent */
export class BrandsListItemComponent extends PureComponent {
    static propTypes = {
        brand: BrandsType.isRequired,
        hideTooltip: PropTypes.func.isRequired,
        imageHeight: PropTypes.number,
        imageWidth: PropTypes.number.isRequired,
        isShowCount: PropTypes.bool.isRequired,
        showTooltip: PropTypes.func.isRequired
    };

    static defaultProps = {
        imageHeight: undefined
    };

    itemRef = createRef();

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    handleHover = () => {
        const { showTooltip } = this.props;

        showTooltip(this.itemRef);
    };

    renderNoImage() {
        const { brand: { letter } } = this.props;

        return (
            <div
              block="BrandsListItem"
              elem="Empty"
            >
                { letter }
            </div>
        );
    }

    renderImage() {
        const {
            brand: {
                image
            },
            imageWidth,
            imageHeight
        } = this.props;

        if (!image) {
            return this.renderNoImage();
        }

        return (
            <div
              block="BrandsListItem"
              elem="ImageWrapper"
              style={ {
                  width: imageWidth,
                  height: imageHeight
              } }
            >
                <Image
                  ratio="custom"
                  src={ `/${ image }` }
                />
            </div>
        );
    }

    renderProductQuantity() {
        const {
            isShowCount,
            brand: { cnt }
        } = this.props;

        if (!isShowCount) {
            return null;
        }

        return (
            <span
              block="BrandsListItem"
              elem="Quantity"
            >
                { cnt }
            </span>
        );
    }

    renderTitle() {
        const {
            brand: {
                label
            }
        } = this.props;

        return (
            <div
              block="BrandsListItem"
              elem="TitleWrapper"
            >
                { label }
                { this.renderProductQuantity() }
            </div>
        );
    }

    renderContent() {
        return (
            <>
                { this.renderImage() }
                { this.renderTitle() }
            </>
        );
    }

    render() {
        const {
            brand: {
                url,
                brandId
            },

            hideTooltip
        } = this.props;

        return (
            <Link
              to={ {
                  pathname: `${ url }`,
                  state: {
                      brandId
                  }
              } }
              block="BrandList"
              elem="Link"
            >
                <div
                  role="button"
                  tabIndex={ 0 }
                  block="BrandsListItem"
                  ref={ this.itemRef }
                  onMouseEnter={ this.handleHover }
                  onMouseLeave={ hideTooltip }
                  onClick={ hideTooltip }
                  onKeyDown={ hideTooltip }
                >
                    { this.renderContent() }
                </div>
            </Link>
        );
    }
}

export default BrandsListItemComponent;
