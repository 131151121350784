/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable no-magic-numbers */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import { BrandListComponentParamsType, BrandListParamsDefaultProps } from '../../type/BrandsType';
import BrandsListItem from '../BrandsListItem';
import BrandsSearch from '../BrandsSearch';
import BrandsSortByLetters from '../BrandsSortByLetters';
import { OVERLAY_LAYOUT, WIDGET_LAYOUT } from './BrandList.config';

import './BrandsList.style';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsList/Component/BrandsListComponent */
export class BrandsListComponent extends PureComponent {
    static propTypes = {
        lettersWithBrandColumns: PropTypes.arrayOf(
            PropTypes.arrayOf(
                PropTypes.string
            )
        ).isRequired,
        brandList: PropTypes.arrayOf(
            PropTypes.shape({
                letter: PropTypes.string,
                map: PropTypes.func
            })
        ).isRequired,
        params: BrandListComponentParamsType,
        setSort: PropTypes.func.isRequired,
        setSearch: PropTypes.func.isRequired,
        filter: PropTypes.string.isRequired,
        layout: PropTypes.string.isRequired
    };

    static defaultProps = {
        params: BrandListParamsDefaultProps
    };

    brandsRef = createRef();

    renderMap = {
        [WIDGET_LAYOUT]: this.renderWidgetLayout.bind(this),
        [OVERLAY_LAYOUT]: this.renderOverlayLayout.bind(this)
    };

    renderBrandListItem(item) {
        const {
            params: {
                imageWidth,
                imageHeight,
                isShowImages,
                layout,
                isShowCount
            }
        } = this.props;

        const { brandId } = item;

        return (
            <BrandsListItem
              key={ brandId }
              layout={ layout }
              brand={ item }
              isShowCount={ isShowCount }
              isShowImages={ isShowImages }
              imageWidth={ imageWidth }
              imageHeight={ imageHeight }
            />
        );
    }

    renderColumn(letter) {
        const { brandList } = this.props;

        return brandList.map((item) => {
            // ↓↓↓ Since brands are sorted in alphabetical order render only brands that has the same first Letter
            if (item.letter !== letter) {
                return null;
            }

            return this.renderBrandListItem(item);
        });
    }

    renderColumns = (letter, index) => {
        const {
            params: {
                columns: columnCount
            },
            layout
        } = this.props;

        // ↓↓↓ Set column style to match column count from BE ↓↓↓
        const columnStyle = layout === WIDGET_LAYOUT
            ? { width: `${100 / columnCount}%` }
            : {};

        return (
            <div
              key={ index }
              block="BrandsList"
              elem="Column"
              mods={ { layout } }
              style={ columnStyle }
            >
                <div
                  block="BrandsList"
                  elem="Title"
                >
                    { letter }
                </div>
                <div
                  block="BrandsList"
                  elem="Content"
                >
                    { this.renderColumn(letter) }
                </div>
            </div>
        );
    };

    // ↓↓↓ Letters list is a row. If column count is 4 then will render 4 letters in this row ↓↓↓
    renderLettersList(lettersList, index) {
        const { layout } = this.props;

        return (
            <div
              key={ index }
              block="BrandsList"
              elem="LetterList"
              mods={ { layout } }
            >
                { lettersList.map(this.renderColumns) }
            </div>
        );
    }

    // ↓↓↓ Brands are sorted in alphabetical and split into rows depending on column count ↓↓↓
    renderBrands() {
        const { lettersWithBrandColumns } = this.props;

        if (!lettersWithBrandColumns) {
            return null;
        }

        return lettersWithBrandColumns.map(
            this.renderLettersList.bind(this)
        );
    }

    renderSearch() {
        const {
            params: {
                isShowSearch
            },
            setSearch
        } = this.props;

        if (!isShowSearch) {
            return null;
        }

        return (
            <BrandsSearch setSearch={ setSearch } />
        );
    }

    renderFilter() {
        const {
            params: {
                isShowFilter,
                isFilterDisplayAll
            },
            layout,
            filter,
            setSort
        } = this.props;

        if (!isShowFilter) {
            return null;
        }

        return (
            <BrandsSortByLetters
              filter={ filter }
              setSort={ setSort }
              layout={ layout }
              isFilterDisplayAll={ isFilterDisplayAll }
            />
        );
    }

    renderWidgetLayout() {
        return (
            <div
              block="BrandsList"
              elem="WidgetLayout"
            >
                { this.renderSearch() }
                { this.renderFilter() }
                <div
                  block="BrandsList"
                  elem="Wrapper"
                  ref={ this.brandsRef }
                >
                { this.renderBrands() }
                </div>
            </div>
        );
    }

    renderOverlayLayout() {
        return (
            <div
              block="BrandsList"
              elem="OverlayLayout"
            >
               <div
                 block="BrandsList"
                 elem="Filter"
               >
                    { this.renderFilter() }
               </div>
                <div
                  block="BrandsList"
                  elem="OverlayWrapper"
                >
                    { this.renderBrands() }
                </div>
            </div>
        );
    }

    render() {
        const { layout } = this.props;

        // eslint-disable-next-line eqeqeq
        if (this.renderMap[layout] == undefined) {
            return this.renderMap[WIDGET_LAYOUT]();
        }

        return this.renderMap[layout]();
    }
}

export default BrandsListComponent;
