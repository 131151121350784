/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CarouselScroll from 'Component/CarouselScroll';
import Slider from 'Component/Slider';
import { DeviceType } from 'Type/Device.type';

import { BrandSliderItemType, BrandSliderType } from '../../type/BrandsType';
import BrandSliderItem from '../BrandSliderItem';

import './BrandSlider.style';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandSlider/Component/BrandSliderComponent */
export class BrandSliderComponent extends PureComponent {
    static propTypes = {
        items: PropTypes.arrayOf(BrandSliderItemType).isRequired,
        params: BrandSliderType.isRequired,
        onActiveImageChange: PropTypes.func.isRequired,
        activeImage: PropTypes.number.isRequired,
        isMobile: PropTypes.bool.isRequired,
        device: DeviceType.isRequired
    };

    renderBrandSliderLabel() {
        const {
            params: {
                sliderTitle = __('Featured Brands'),
                sliderTitleColor
            }
        } = this.props;

        return (
            <div block="BrandSlider" elem="HeaderContainer" style={ { color: `${ sliderTitleColor }` } }>
            { sliderTitle }
            </div>
        );
    }

    renderBrandCarousel() {
        const {
            items,
            params: {
                itemsNumber = 10
            }
        } = this.props;

        if (items.length === 0 || Object.keys(items).length === 0) {
            return this.renderBrandSliderPlaceholder();
        }

        return (
            <CarouselScroll
              showedItemCount={ itemsNumber }
            >
                { this.renderBrandSliderItems() }
            </CarouselScroll>
        );
    }

    renderBrandSlider() {
        const {
            onActiveImageChange,
            activeImage,
            device,
            items
        } = this.props;

        if (items.length === 0 || Object.keys(items).length === 0) {
            return this.renderBrandSliderPlaceholder();
        }

        return (
            <Slider
              mix={ { block: 'BrandSlider', elem: 'SliderItemWrapper' } }
              showCrumbs
              sliderRef={ this.sliderRef }
              showArrows={ false }
              isVertical={ false }
              activeImage={ activeImage }
              onActiveImageChange={ onActiveImageChange }
              device={ device }
            >
                { this.renderBrandSliderItems() }
            </Slider>
        );
    }

    renderBrandSliderItems() {
        const {
            items,
            params: {
                imageWidth = 100,
                imageHeight = 100,
                showLabel
            }
        } = this.props;

        const params = { imageWidth, imageHeight, showLabel };

        return items.map((item) => this.renderBrandSliderItem(item, params));
    }

    renderBrandSliderItem(item, params) {
        return (
            <BrandSliderItem
              item={ item }
              params={ params }
              key={ item.label }
            />
        );
    }

    renderBrandSliderPlaceholder() {
        return (
            <div block="BrandSlider" elem="Placeholder">
                <span block="BrandSlider" elem="Message">
                    { __('No featured brands added.') }
                </span>
            </div>
        );
    }

    render() {
        const {
            params: {
                sliderHeaderColor
            },
            isMobile
        } = this.props;

        if (isMobile) {
            return (
                <div block="BrandSlider" style={ { backgroundColor: sliderHeaderColor } }>
                    { this.renderBrandSliderLabel() }
                    { this.renderBrandSlider() }
                </div>
            );
        }

        return (
            <div block="BrandSlider" style={ { backgroundColor: sliderHeaderColor } }>
                { this.renderBrandSliderLabel() }
                { this.renderBrandCarousel() }
            </div>
        );
    }
}

export default BrandSliderComponent;
