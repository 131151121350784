/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { BrandListParamsType, BrandsType } from '../../type/BrandsType';
import { filterBrandListBySearchAndLetter, filterLetters } from '../../util/Brands';
import { BRAND_LIST_DEFAULT_FILTER, DEFAULT_COLUMN_COUNT, DEFAULT_IMAGE_WIDTH } from './BrandList.config';
import BrandsList from './BrandsList.component';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    brandListConfig: state.ConfigReducer.brandsConfig.ambrandlist
});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsList/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandsList/Container/BrandsListContainer */
export class BrandsListContainer extends PureComponent {
    static propTypes = {
        brandListConfig: PropTypes.shape({
            items: PropTypes.arrayOf(
                BrandsType
            ),
            all_letters: PropTypes.string
        }).isRequired,
        isMenuOverlay: PropTypes.bool,
        layout: PropTypes.string.isRequired,
        widget: PropTypes.shape({
            params: BrandListParamsType
        })
    };

    static defaultProps = {
        isMenuOverlay: false,
        widget: { params: {} }
    };

    containerFunctions = {
        setSearch: this.setSearch.bind(this),
        setSort: this.setSort.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            search: '',
            filter: BRAND_LIST_DEFAULT_FILTER
        };
        // ↑↑↑ filter by 'all brands' by default
    }

    containerProps() {
        const {
            widget: {
                params: {
                    columns: columnsOptional,
                    displayZero: displayZeroOptional,
                    filterDisplayAll: filterDisplayAllOptional,
                    imageWidth: imageWidthOptional,
                    showCount: showCountOptional,
                    showFilter: showFilterOptional,
                    showImages: showImagesOptional,
                    showSearch: showSearchOptional,
                    type: typeOptional
                } = {}
            } = {},
            brandListConfig: {
                items: allBrands,
                all_letters: allLettersString
            } = {},
            layout
        } = this.props;

        const { search, filter } = this.state;

        const params = {
            columns: columnsOptional || DEFAULT_COLUMN_COUNT,
            displayZero: !!displayZeroOptional || false,
            isFilterDisplayAll: !!filterDisplayAllOptional || true,
            imageWidth: imageWidthOptional || DEFAULT_IMAGE_WIDTH,
            isShowCount: !!showCountOptional || false,
            isShowFilter: !!showFilterOptional || true,
            isShowImages: !!showImagesOptional || true,
            isShowSearch: !!showSearchOptional || false,
            type: typeOptional || 'AmastyShopByBrandList'
        };

        // ↓↓↓ convert value from backend (example: 'A,B,E,F,G') to array
        const allLetters = allLettersString?.split(',');

        const isFiltered = search.length > 0 || filter !== 'all brands';
        const brandList = isFiltered ? filterBrandListBySearchAndLetter(allBrands, search, filter) : allBrands;
        const lettersWithBrand = isFiltered > 0 ? filterLetters(brandList) : allLetters;
        let lettersWithBrandColumns = [];
        if (lettersWithBrand) {
            // ↓↓↓ convert letter array to chunks by column count to [A,B,E,F], [B,D,H,K] (if column count is 4)
            lettersWithBrandColumns = lettersWithBrand.map((e, i) => (
                i % params.columns === 0 ? lettersWithBrand.slice(i, i + params.columns) : null
            )).filter((e) => e);
        }


        return {
            params,
            brandList,
            lettersWithBrandColumns,
            filter,
            layout
        };
    }

    setSearch(searchTerm) {
        this.setState({ search: searchTerm });
    }

    setSort(letter) {
        this.setState({ filter: letter });
    }

    render() {
        return (
            <BrandsList
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandsListContainer);
