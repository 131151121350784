/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DeviceType } from 'Type/Device.type';

import { BrandSliderItemType, BrandSliderType } from '../../type/BrandsType';
import BrandSlider from './BrandSlider.component';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandSlider/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    brandSlider: state.ConfigReducer.brandsConfig.ambrandslider,
    isMobile: state.ConfigReducer.device.isMobile,
    device: state.ConfigReducer.device

});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandSlider/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandSlider/Container/BrandSliderContainer */
export class BrandSliderContainer extends PureComponent {
    static propTypes = {
        brandSlider: PropTypes.shape({
            items: PropTypes.arrayOf(BrandSliderItemType)
        }).isRequired,
        widget: PropTypes.shape({
            params: BrandSliderType
        }).isRequired,
        isMobile: PropTypes.bool.isRequired,
        device: DeviceType.isRequired
    };

    containerFunctions = {
        onActiveImageChange: this.onActiveImageChange.bind(this)
    };

    onActiveImageChange(activeImage) {
        this.setState({
            activeImage
        });
    }

    containerProps() {
        const {
            widget: {
                params
            },
            brandSlider: { items = {} } = {},
            isMobile,
            device
        } = this.props;
        const {
            activeImage
        } = this.state;

        return {
            items,
            device,
            isMobile,
            activeImage,
            params
        };
    }

    __construct(props) {
        super.__construct(props);
        this.state = {
            activeImage: 0
        };
    }

    render() {
        return (
            <BrandSlider
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandSliderContainer);
